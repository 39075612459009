import React, { useState, createContext, useContext, useEffect, useMemo, useCallback, useRef } from "react";
import { ModelContext } from "providers/ModelProvider";
import FontLoader from 'components/FontLoader';
import Preload from 'preload-it';
import produce from 'immer';
import Conf from 'Conf';
import fetch from "shared/providers/CustomFetch";
import Queue from "queue";
export const PreloadContext = createContext({});

const staticFiles=[
  '/app-assets/logo.svg',
  '/app-assets/arrow-down-white.svg',
  '/shared-assets/arrow-left.svg',
  '/shared-assets/arrow-right.svg',
  '/shared-assets/bulle-left.svg',
  '/shared-assets/bulle-right.svg',
  '/shared-assets/avatar-full.svg',
  '/shared-assets/failed.svg',
  '/shared-assets/missed-call.svg',
  '/shared-assets/home.svg',
  '/shared-assets/cog.svg',
  '/shared-assets/tapuscrit.svg',
  '/shared-assets/bulles.svg',
  '/shared-assets/docs.svg',
  '/shared-assets/audio-on.svg',
  '/shared-assets/audio-off.svg',
  '/shared-assets/voix.svg',
  '/shared-assets/pause.svg',
  '/shared-assets/play.svg',
  '/shared-assets/stop.svg',
  '/shared-assets/burger.svg',
  '/shared-assets/close.svg',
  '/shared-assets/more.svg',
  '/shared-assets/skip.svg',
  '/shared-assets/warning.svg',
]
const fonts=[
  { fontFamily: "Roboto", finalWidth: 520 },
  { fontFamily: "francis-bold", finalWidth: 309 },
  { fontFamily: "francis-bold-italic", finalWidth: 302 },
  { fontFamily: "francis-light", finalWidth: 162 },
  { fontFamily: "francis-light-italic", finalWidth: 166 },
  { fontFamily: "francis-medium", finalWidth: 256 },
  { fontFamily: "francis-medium-italic", finalWidth: 253 },
  { fontFamily: "francis-regular", finalWidth: 200 },
  { fontFamily: "francis-regular-italic", finalWidth: 201 },
]
const fileSizeSI=(a,b,c,d,e)=>{
 return (b=Math,c=b.log,d=1e3,e=c(a)/c(d)|0,a/b.pow(d,e)).toFixed(2)
 +' '+(e?'kMGTPEZY'[--e]+'B':'Bytes')
}
const cacheName='v1-front-suppliques';
const PreloadProvider = ({children})=>{
  const [ cachedFiles,setCachedFiles ] = useState([]);
  const [ files,setFiles ] = useState([]);
  const [ preloadList,setPreloadList ] = useState([]);
  const [ preloadLaterList,setPreloadLaterList ] = useState([]);
  const [ dynamicFiles,setDynamicFiles ] = useState([]);
  const [ dynamicFilesLater,setDynamicFilesLater ] = useState([]);
  const [ fontsProgress,setFontsProgress ] = useState(0);
  const [ filesProgress,setFilesProgress ] = useState(0);
  const [ fontsReady,setFontsReady ] = useState(false);
  const [ filesReady,setFilesReady ] = useState(false);
  const preload = useRef(Preload());
  const preloadLater = useRef(Preload());
  const started = useRef(false);
  const startedLater = useRef(false);
  const cache=useRef(null);
  const { getCollection } = useContext(ModelContext);
  //init preloaders
  useEffect(()=>{
    preload.current.fetch=fetch;
    preload.current.onerror = item => {
      console.log(item);
    }
    preload.current.oncomplete = items => {
      console.log('files preload done');
      setFilesProgress(100);
      setFilesReady(true);
      setFiles((state)=>produce(state,(draft)=>{
        items.forEach((item, i) => {
          if (item.blobUrl) {
            const idx=draft.findIndex((o)=>o.url===item.url);
            if (idx!==-1) {
              draft[idx]=item;
              document.documentElement.style.setProperty('-'+item.url.replace(/[^a-zA-Z0-9]/g,'-'), `url(${item.blobUrl})`);
            }
          }
        });
      }));
    }
    preload.current.onprogress = event => {
      setFilesProgress(event.progress);
      //console.log('dynamicFiles progress',event.progress+'%')
    }
    preloadLater.current.fetch=fetch;
    preloadLater.current.onerror = item => {
      console.log(item);
    }
    preloadLater.current.oncomplete = items => {
      console.log('files later preload done');
      setFiles((state)=>produce(state,(draft)=>{
        items.forEach((item, i) => {
          if (item.blobUrl) {
            const idx=draft.findIndex((o)=>o.url===item.url);
            if (idx!==-1) {
              draft[idx]=item;
              document.documentElement.style.setProperty('-'+item.url.replace(/[^a-zA-Z0-9]/g,'-'), `url(${item.blobUrl})`);
            }
          }
        });
      }));
    }
  },[setFiles,setFilesProgress])
  //list already cached files keys
  useEffect(()=>{
    if('caches' in window) {
      console.log('restore from cache');
      caches.open(cacheName)
      .then((c) => {
        cache.current=c;
        c.keys().then(function(response) {
          setCachedFiles(response);
        });
      });
    }
  },[]);
  //list dynamic files to preload now
  useEffect(()=>{
    if (dynamicFiles.length===0) {
      const application=getCollection('application');
      const app=application[0] || {};
      const portraits=getCollection('portrait');
      const liens=getCollection('lien');
      const lettres=getCollection('lettre');
      const sons=getCollection('son');
      const dynamicAssets=[];
      if (app.background && app.background[0]) {
        const url=Conf.filesUrl+app.background[0].url;
        if (dynamicAssets.indexOf(url)) dynamicAssets.push(url);
      }
      if (app.animation && app.animation[0]) {
        const url=Conf.filesUrl+app.animation[0].url;
        if (dynamicAssets.indexOf(url)) dynamicAssets.push(url);
      }
      if (app.chronologie && app.chronologie[0]) {
        const url=Conf.filesUrl+app.chronologie[0].url+(app.chronologie[0].type==='image/svg+xml' ? '.svg' : '');
        if (dynamicAssets.indexOf(url)) dynamicAssets.push(url);
      }
      portraits.forEach((item, i) => {
        item.photo && item.photo.forEach((media)=>{
          const url=Conf.filesUrl+media.url+'-300';
          if (dynamicAssets.indexOf(url)) dynamicAssets.push(url);
        });
      });
      liens.forEach((item, i) => {
        item.images && item.images.forEach((media)=>{
          const url=Conf.filesUrl+media.url+'-600';
          if (dynamicAssets.indexOf(url)) dynamicAssets.push(url);
        });
      });
      lettres.forEach((item, i) => {
        item.vignette && item.vignette.forEach((media)=>{
          const url=Conf.filesUrl+media.url+'-300';
          if (dynamicAssets.indexOf(url)) dynamicAssets.push(url);
        });
      });
      sons.forEach((son)=>{
          son.listeners.forEach((listener, i) => {
            if (listener.hook) {
              if ((
                listener.hook==='animation'
                || listener.hook==='message'
                || listener.hook.startsWith('theme')
                || listener.hook.startsWith('landing')
              ) && son.fichier && son.fichier[0]) {
                const url=Conf.filesUrl+son.fichier[0].url+'-44100.mp3';
                if (dynamicAssets.indexOf(url)) dynamicAssets.push(url);
              }
            }
          });
        });
      if (dynamicAssets.length>0) {
        console.log('list dynamicFiles');
        setDynamicFiles(dynamicAssets);
      }
    }
  },[getCollection,dynamicFiles,setDynamicFiles]);
  //list dynamic files to preload later
  useEffect(()=>{
    if (dynamicFilesLater.length===0) {
      const lettres=getCollection('lettre');
      const dynamicAssetsLater=[];
      lettres.forEach((item, i) => {
        item.scan && item.scan.forEach((media)=>{
          const url=Conf.filesUrl+media.url+(media.type==='image/svg+xml' ? '' : '-2000');
          if (dynamicAssetsLater.indexOf(url)) dynamicAssetsLater.push(url);
        });
      });
      if (dynamicAssetsLater.length>0) {
        console.log('list dynamicFilesLater');
        setDynamicFilesLater(dynamicAssetsLater);
      }
    }
  },[getCollection,dynamicFilesLater,setDynamicFilesLater]);
  //update filesList & preloadLists
  useEffect(()=>{
    if (dynamicFiles.length>0 && dynamicFilesLater.length>0) {
      const allFiles=[
        ...staticFiles.map((url)=>{return{url}}),
        ...dynamicFiles.map((url)=>{return{url}}),
        ...dynamicFilesLater.map((url)=>{return{url,later:true}}),
      ];
      const toPreload=[];
      const toPreloadLater=[];
      const list=[];
      const q=new Queue({concurrency:1});
      allFiles.forEach((item)=>{
        q.push((cb)=>{
          const cachedFile=cachedFiles.find((o)=>o.url.endsWith(item.url));
          if(cachedFile) {
            let size=0;
            cache.current.match(item.url)
            .then(response => response.blob())
            .then(blob => {
              size=blob.size;
              return URL.createObjectURL(blob)
            })
            .then((blobUrl)=>{
              list.push({...item, blobUrl, size});
              document.documentElement.style.setProperty('-'+item.url.replace(/[^a-zA-Z0-9]/g,'-'), `url(${blobUrl})`);
              cb();
            })
          } else {
            if (item.later) toPreloadLater.push(item);
            else toPreload.push(item);
            list.push(item);
            cb();
          }
        });
      });
      q.on('end',()=>{
        console.log(toPreload);
        setFiles(list);
        setPreloadList(toPreload);
        if (toPreload.length===0) {
          console.log('files preload done');
          setFilesProgress(100);
          setFilesReady(true);
        }
        setPreloadLaterList(toPreloadLater);
        if (toPreloadLater.length===0) {
          console.log('files preload later done');
        }
      });
      q.start();
    }
  },[cachedFiles,dynamicFiles,dynamicFilesLater,setFiles,setPreloadList,setPreloadLaterList]);
  //preload
  useEffect(()=>{
    if (preloadList.length>0 && !started.current) {
      console.log('start files preload');
      started.current=true;
      preload.current.fetch(preloadList.map((o)=>o.url));
    }
  },[preloadList]);
  //preload later
  useEffect(()=>{
    if (filesReady && preloadLaterList.length>0 && !startedLater.current) {
      console.log('start files later preload');
      startedLater.current=true;
      const t=setTimeout(()=>{
        preloadLater.current.fetch(preloadLaterList.map((o)=>o.url));
      },60000);
      return ()=>{
        clearTimeout(t);
      }
    }
  },[preloadLaterList,filesReady]);
  //font handler
  const handleFontLoaded=useCallback(()=>{
    setFontsProgress((state)=>state+1);
  },[setFontsProgress]);
  useEffect(()=>{
    if(fontsProgress===fonts.length) {
      setFontsReady(true);
      console.log('fonts preload done');
    }
  },[fontsProgress,setFontsReady]);
  const file=useCallback((url,full=false)=>{
    const res=files.find((o)=>o.url===url);
    if (res && full) return res;
    if (res && res.blobUrl) return res.blobUrl;
    return url;
  },[files]);
  const totalSize=useMemo(()=>files.reduce((total,f)=>{
    return f.size+total;
  },0),[files]);
  return (
    <PreloadContext.Provider value={{preloadList, preloadLaterList, files, file, filesReady:filesReady && fontsReady, filesProgress, totalSize:fileSizeSI(totalSize)}}>
        {fontsProgress!==fonts.length && fonts.map((font)=><FontLoader key={font.fontFamily} font={font} onLoaded={handleFontLoaded}/>)}
        {children}
    </PreloadContext.Provider>
  );
}
export default PreloadProvider;
