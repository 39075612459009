import Queue from 'queue';

const preloadOne=(url, done, that)=>{
	const xhr = new XMLHttpRequest()
	xhr.open('GET', url, true)
	xhr.responseType = 'blob'

	const item = that.getItemByUrl(url)
	item.xhr = xhr

	xhr.onprogress = event => {
		if (!event.lengthComputable) return false
		item.completion = parseInt((event.loaded / event.total) * 100)
		item.downloaded = event.loaded
		item.total = event.total
		that.updateProgressBar(item)
	}
	xhr.onload = event => {
		const type = event.target.response.type
		const responseURL = event.target.responseURL

		item.fileName = responseURL.substring(responseURL.lastIndexOf('/') + 1)
		item.type = type
		item.status = xhr.status

		if (xhr.status === 404) {
			item.blobUrl = item.size = null
			item.error = true
			that.onerror(item)
		} else {
			const blob = new Blob([event.target.response], { type: type })
			item.blobUrl = URL.createObjectURL(blob)
			item.size = blob.size
			item.error = false
		}
		done(item)
	}
	xhr.send()
}

export default function fetch(list) {
	return new Promise((resolve, reject) => {
		this.loaded = list.length
    const q=new Queue({concurrency:1});
		for (let item of list) {
			this.state.push({ url: item })
      q.push((cb)=>{
        preloadOne(item, item => {
					this.onfetched(item)
  				this.loaded--
  				if (this.loaded === 0) {
  					this.oncomplete(this.state)
  					resolve(this.state)
  				}
          cb();
  			},this)
      });
		}
		q.start();
	})
}
